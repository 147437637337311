/* Mobile Responsiveness */
@media screen and (max-width: 640px) {
  /* Main container adjustments */
  .flex-col.justify-start.min-h-screen.bg-gray-50 {
    padding: 16px 12px;
  }

  /* Header text adjustments */
  .text-center.mb-8 {
    margin-bottom: 16px;
  }

  .text-center.mb-8 h2 {
    font-size: 24px;
    line-height: 1.2;
  }

  .text-center.mb-8 p {
    width: 100% !important;
    padding: 0 12px;
    font-size: 14px;
  }

  /* Integration cards container */
  .flex.flex-col.lg\:flex-row.gap-3 {
    flex-direction: column;
  }

  .lg\:w-72 {
    width: 100%;
  }

  /* Integration card adjustments */
  .relative.group.bg-white.p-6 {
    padding: 12px;
  }

  .relative.group.bg-white.p-6 img {
    height: 36px;
    width: 36px;
  }

  .relative.group.bg-white.p-6 h3 {
    font-size: 16px;
  }

  /* Main content area */
  .flex-1.max-w-\[calc\(100\%-336px\)\] {
    max-width: 100% !important;
  }

  /* Status panel */
  [data-testid="status-panel"] {
    margin-bottom: 16px;
  }

  [data-testid="status-panel"] .flex.items-center.space-x-4 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  /* Steps content and TOC layout */
  .flex.gap-8 {
    flex-direction: column-reverse;
  }

  .lg\:w-56 {
    width: 100%;
    margin-bottom: 16px;
  }

  /* Table of contents */
  .bg-white.p-4.rounded-lg.shadow-sm.border.border-gray-200 nav {
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;
    margin: 0 -12px;
    padding: 0 12px;
  }

  .bg-white.p-4.rounded-lg.shadow-sm.border.border-gray-200 nav a {
    flex: 0 0 auto;
    white-space: nowrap;
  }

  /* Step content */
  .StepContent {
    padding: 16px 12px;
  }

  .StepContent img {
    width: 100%;
    height: auto;
  }

  /* Modal adjustments */
  .fixed.inset-0.bg-black.bg-opacity-50.flex.items-center.justify-center.z-50 .bg-white {
    width: 90%;
    margin: 16px;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* Buttons */
  button.inline-flex.items-center.justify-center {
    width: 100%;
    margin: 8px 0;
  }

  /* Warning/info boxes */
  .bg-yellow-50.border-l-4,
  .bg-blue-50.p-6 {
    margin: 16px 0;
    padding: 12px;
  }

  .integration-cards-container {
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .integration-cards-wrapper {
    display: flex;
    transition: transform 0.3s ease-out;
    width: 100%;
  }

  .integration-card {
    flex: 0 0 100%;
    padding: 16px;
    transition: transform 0.3s ease;
  }

  .card-indicators {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }

  .card-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #CBD5E0;
    transition: background-color 0.3s ease;
  }

  .card-indicator.active {
    background-color: #3B82F6;
  }

  .status-panel-mobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .status-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    background: #f8fafc;
    border-radius: 6px;
  }

  .status-info-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .status-button-row {
    width: 100%;
  }

  .status-button-row button {
    width: 100%;
    justify-content: center;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 360px) {
  .text-center.mb-8 h2 {
    font-size: 20px;
  }

  .StepContent h4 {
    font-size: 16px;
  }

  .text-13 {
    font-size: 12px;
  }
} 